@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
html {
  scroll-behavior: smooth;
}

section h2 {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 8vh;
}

@media only screen and (max-width: 400px) {
  section h2 {
    font-size: 6vh;
  }
  section h5 {
    font-size: medium;
  }
}

.outer {
  animation: fadein 3s;
}

section h3 {
  font-size: 5vh;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #000000;
  width: 250px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

@keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

h4 {
  border-bottom: 1px solid lightslategray;
}
section h4,
section h5 {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
}

.project h4 {
  font-weight: 300;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  text-align: center;
  border-bottom: none;
  padding-top: 10px;
}

img {
  height: 280px;
  width: 445px;
  -moz-box-shadow: 2px 2px 2px black;
  -webkit-box-shadow: 2px 2px 2px black;
  box-shadow: 2px 2px 2px black;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}

section {
  display: flex;
  align-items: center;
  position: relative;
  height: auto;
  min-height: 100vh !important;
  width: 100%;
  scroll-snap-align: end;
  padding: 0;
  margin: 0;
  background: linear-gradient(-45deg, #ca9787, #812649, #4ba1c1, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  color: white;
  text-align: center;
  overflow-y: auto;
}

section:nth-child(even) {
  background: linear-gradient(-45deg, #b2a09a, #885b6c, #6a95a4, #568f81);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding: 0;
  margin: 0;
  scroll-snap-align: end;
}

.container-fluid {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

body {
  font-family: "Quicksand", sans-serif;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 300;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.003em;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  padding: 0px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.container-fluid {
  padding: 0px;
}

.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  animation: name duration timing-function delay iteration-count direction
    fill-mode;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  scroll-snap-align: end;
  padding: 4px 4px;
  margin: 0px;
  background: linear-gradient(-45deg, #ca9787, #812649, #4ba1c1, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

section:nth-child(even) {
  background: linear-gradient(-45deg, #b2a09a, #885b6c, #6a95a4, #568f81);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding: 0;
  margin: 0;
  scroll-snap-align: end;
}

section:nth-last-of-type() {
  height: 100%;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 12px;
  margin: 4px;
}

.swipe {
  display: grid;
  place-content: center;
  width: 150px;
  height: 100vh;
  background-color: lightblue;
}

ul {
  margin: 0 auto;
  text-align: center;
  list-style-type: none;
  padding: 1px;
}

li {
  margin: 2px;
}
@-webkit-keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  60% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
@-moz-keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(30px);
  }
  60% {
    transform: translateX(15px);
  }
}
@keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  60% {
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}

@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/* /right bounce */

.arrow-right {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float: right;
}

.arrow-left {
  -webkit-animation: bounceLeft 2s infinite;
  animation: bounceLeft 2s infinite;
  float: left;
}

@keyframes up-bump {
  0% {
    padding-top: 2em;
  }
  50% {
    padding-top: 1.5em;
  }
  100% {
    padding-top: 2em;
  }
}
@keyframes down-bump {
  0% {
    padding-top: 2em;
  }
  50% {
    padding-top: 2.5em;
  }
  100% {
    padding-top: 2em;
  }
}

.box-font {
  font-size: larger;
  text-transform: uppercase;
}

.swipe {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10%;
  background-color: red;
  color: white;
  text-align: center;
}

.connection,
.box {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 8px;
  margin: 8px;
  color: #fff;
  text-decoration: none;
  border: 2px solid lightslategray;
  border-radius: 2px;
}

.connection:before,
.box::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: lightblue;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.connection:hover,
.connection:focus,
.connection:active,
.box:hover,
.box:focus,
.box:active {
  color: black;
  background-size: 100% 2px; /*width:100% height:2px*/
  transform: scale(1.1);
}
.connection:hover:before,
.connection:focus:before,
.connection:active:before,
.box:hover:before,
.box:focus:before,
.box:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.arrow-down {
  animation: bounce-down 2s infinite ease;
}

.bouncy-director {
  opacity: 0.7;
  margin: 12px;
}

@keyframes bounce-down {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.skills {
  height: 316px;
  width: 25rem;
}

@media only screen and (max-width: 376px) {
  .skills {
    height: 316px;
    max-width: 350px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0;
  }

  .item {
    max-width: 375px;
  }

  .content > h3 {
    margin-top: 12px;
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .skills {
    align-items: center;
    align-content: center;
    height: 316px;
    max-width: 18rem;
    margin-right: 0px;
    margin-left: 0px;
  }

  .item {
    max-width: 600px;
  }

  .skills > ul {
    margin: 0px;
    padding: 0px;
  }

  .content {
    margin: 0px;
    padding: 0px;
  }

  .project {
    max-width: "350px";
    height: "545px";
    margin-top: 1px;
  }
  .project > img {
    max-width: 350px;
  }
}

.project {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  color: #fff;
  text-decoration: none;
  border: 2px solid lightslategray;
  border-radius: 30px;
  justify-content: "center";
  align-items: "center";
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 12px;
  max-width: 400px;
  height: 550;
}

.project > img {
  max-width: 400px;
}

.project > a {
  border: white thin solid;
}

strong {
  font-weight: 600;
  text-transform: uppercase;
}
